/* Uniboard: keep the same page layout across pages */

$scrollbar-width: 20px;

$col3-uniboard-side-min: var(---col3-uniboard-side-min, 250px);
$col3-uniboard-table-min: 240px;
$col3-uniboard-side: minmax(#{$col3-uniboard-side-min}, 350px);
$col3-uniboard-table: minmax(#{$col3-uniboard-table-min}, 400px);
$col3-uniboard-controls: 3rem;
$col3-min-bottom-margin: 1rem;

$col3-uniboard-max-width: calc(
  100vw - #{$col3-uniboard-side-min} - #{$block-gap} - #{$col3-uniboard-table-min} - 2 *
    var(---main-margin, 0px) - #{$scrollbar-width}
);
$col3-uniboard-max-height: calc(100vh - #{$site-header-outer-height} - #{$col3-min-bottom-margin});
$col3-uniboard-max-size: min(#{$col3-uniboard-max-width}, #{$col3-uniboard-max-height});
$col3-uniboard-width: calc(#{$col3-uniboard-max-size} * var(---board-scale));

// default zoom setting is 85% which gives a board-scale of 0.85*0.75+0.25 = 0,8875 ≈ 0.9
// this is used for 3-col pages that don't actually have a board, e.g. tournament pages
// on these pages, the height is irrelevant, so we just use the width
//
// currently, this is still calculated using the old/fallback formular
$uniboard-default-scale: 0.9;
$col3-uniboard-default-min-width: 500px;
$col3-uniboard-default-max-width: calc(
  100vh * #{$uniboard-default-scale} - #{$site-header-outer-height} - #{$col3-uniboard-controls}
);
$col3-uniboard-default-width: minmax(
  #{$col3-uniboard-default-min-width},
  #{$col3-uniboard-default-max-width}
);
$col2-uniboard-default-width: $col3-uniboard-default-width;

$col2-uniboard-table: $col3-uniboard-table;
$col2-uniboard-controls: $col3-uniboard-controls;

$col2-uniboard-max-width: calc(
  100vw - #{$block-gap} - #{$col3-uniboard-table-min} - 2 * var(---main-margin, 0px) - #{$scrollbar-width}
);
$col2-uniboard-max-height: $col3-uniboard-max-height;
$col2-uniboard-max-size: min(#{$col2-uniboard-max-width}, #{$col2-uniboard-max-height});
$col2-uniboard-width: calc(#{$col2-uniboard-max-size} * var(---board-scale));

$col2-uniboard-default-max-width: calc(#{$col2-uniboard-max-size} * #{$uniboard-default-scale});
$col2-uniboard-default-width: minmax(
  #{$col3-uniboard-default-min-width},
  #{$col2-uniboard-default-max-width}
);

$col2-uniboard-squeeze-table: minmax(200px, 240px);
$col2-uniboard-squeeze-width: minmax(
  calc(55vmin),
  calc(100vh - #{$site-header-outer-height} - #{$block-gap})
);

$col1-uniboard-controls: 5rem;
